<template>
    <div class="forget-page">
        <basic-page>
            <template slot="left">
                <basic-page-left></basic-page-left>
            </template>
            <template slot="right">
                <div class="forget-content">
                    <div class="form-content">
                        <div class="width-full flex flex-justify-between fonts-15 cursor-pointer margin-b-15">
                            <div class="color-666666" @click="goToLogin"><i class="el-icon-back"></i> {{$t('system.forget_page_form_title')}}</div>
                        </div>
						<el-form style="width: 100%">
							<el-form-item label="" style="margin-bottom: 15px;">
								<el-input :placeholder="$t('system.forget_page_form_phone_placeholder')" required clearable
                                    autocomplete="new"
                                    v-model="form.phone" 
                                    @keydown.enter.native="(e) => submitForget()">
                                    <el-select v-model="form.countryCode" filterable slot="prepend" placeholder="请选择" style="width: 85px;">
                                        <template v-for="(item, index) in countryCodeList">
                                            <el-option :label="`+${item.code}`" :value="item.code" :key="index">{{ `(+${item.code}) ${item.name}` }}</el-option>
                                        </template>
                                    </el-select>
                                </el-input>
							</el-form-item>
                            <el-form-item label="" style="margin-bottom: 15px;">
                                <div class="flex">
                                    <div class="margin-r-10">
                                        <el-input :placeholder="$t('system.forget_page_form_captcha_placeholder')" required clearable 
                                            autocomplete="new"
                                            v-model="form.captcha" 
                                            @keydown.enter.native="(e) => submitForget()"
                                        ></el-input>
                                    </div>
                                    <div style="width: 120px;">
                                        <el-button round plain :disabled="hasSend" @click="getCaptcha" style="padding: 0;width:100%;height:40px;">{{hasSend?`${reSendTime}${$t('system.login_page_bindphone_form_send_captcha_seconds')}`:$t('system.login_page_bindphone_form_send_captcha')}}</el-button>
                                    </div>
                                </div>
							</el-form-item>
							<el-form-item label="" style="position: relative;margin-bottom: 15px;">
								<el-input :placeholder="$t('system.forget_page_form_password_placeholder')" required :type="showPassword?'text':'password'"
									autocomplete="new-password"
                                    v-model="form.password"
									@keydown.enter.native="(e) => submitForget()"
								></el-input>
								<div class="show-password" @click="setShowPassword">
                                    <svg-icon name="ib-open-eye" v-if="showPassword"></svg-icon>
                                    <svg-icon name="ib-close-eye" v-else></svg-icon>
								</div>
							</el-form-item>
						</el-form>
						<el-button type="primary margin-t-30" round @click="submitForget" :loading="loading">{{$t('system.forget_page_form_button')}}</el-button>
					</div>
                </div>
            </template>
        </basic-page>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Base64 } from "js-base64";
import { userApi } from '@/utils/api';
import tools from '@/utils/tools';
import BasicPage from './components/BasicPage';
import BasicPageLeft from './components/BasicPageLeft'
export default {
    components: { BasicPage, BasicPageLeft, },
    data() {
        return {
            loading: false,
            form: {
                countryCode: '86',
                phone: '',
                captcha: '',
                password: '',
            },
            showPassword: false,

            hasSend: false,
            reSendTime:60,

            countryCodeList: [],

            sendTimer:null,
        }
    },
    computed:{
        ...mapGetters({
            isLogin:'user/loginStatus',
        }),
    },
    created(){
        if (this.isLogin){
            this.$router.replace('/');
        }
        this.countryCodeList = this.$t('common.country_code_list');
    },
    methods: {
        submitForget(){
            let self = this;
            if(!this.form.phone){
                this.$message.error(this.$t('system.forget_page_form_verification_message_1'));
                return;
            }
            /* if(!tools.checkPhone(this.form.phone)){
                this.$message.error(this.$t('system.forget_page_form_verification_message_2'));
                return;
            } */
            if(!this.form.captcha){
                this.$message.error(this.$t('system.forget_page_form_verification_message_3'));
                return;
            }
            if(!this.form.password){
                this.$message.error(this.$t('system.forget_page_form_verification_message_4'));
                return;
            }
            let rst = /^(?=.*[a-z])(?=.*\d).{6,20}$/.test(this.form.password);
            if(!rst){
                this.$message.error(this.$t('system.forget_page_form_verification_message_5'));
                return;
            }
            let url = userApi.submitForgetPwd;
            let postData = {
                account: this.form.phone,
                password: Base64.encode(this.form.password),
                captcha: this.form.captcha,
                regionCode: '+' + this.form.countryCode
            }
            this.loading = true;
            this.$ajax.post(url, postData).then(res=> {
                if(res.status === 200){
                    this.$message.success(this.$t('system.forget_page_form_submit_success'));
                    // this.$router.replace('/login');
                    setTimeout(()=>{
                        location.replace('/login');
                        // self.$router.replace('/login');
                    }, 1000);
                }
            }).finally(()=> {
                this.loading = false;
            }); 
        },
        setShowPassword(){
            this.showPassword = !this.showPassword;
        },
        getCaptcha(){
            let self = this;
            if(!this.form.phone){
                this.$message.error(this.$t('system.forget_page_form_verification_message_1'));
                return;
            }
            /* if(!tools.checkPhone(this.form.phone)){
                this.$message.error(this.$t('system.forget_page_form_verification_message_2'));
                return;
            } */
            this.$ajax.post(userApi.sendCaptcha, {account: this.form.phone, regionCode: '+' + this.form.countryCode}).then(res=> {
                if(res.status === 200){
                    this.$message.success(this.$t('system.forget_page_form_sent_captcha_message'));
                    this.hasSend = true;
                    this.reSendTime = this.reSendTime - 1;
                    this.sendTimer=setInterval(()=> {
                        self.reSendTime = self.reSendTime - 1;
                        if(self.reSendTime < 0){
                            clearInterval(self.sendTimer);
                            self.reSendTime = 60;
                            self.hasSend = false;
                        }
                    }, 1000);
                }
            })
        },
        goToLogin(){
            location.href = '/login';
        }
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
.forget-page{
    width: 100%;
	height: 100%;
    .forget-content {
        // padding-top: 30px;
		width: 460px;
		height: 460px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
</style>
